.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 55px;
  padding: 0 15px;
  background: linear-gradient(70deg, var(--blue) -20%, var(--green) 130%);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.headerLogo > img, .headerLogo > svg {
  height: 26px;
  width: auto;
}
.headerUser {
  color: var(--white);
  text-decoration: none;
  font-weight: 600;
}