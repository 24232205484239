.auth {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, var(--blue) 25%, var(--green));
}
.authModal {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  max-width: 80vw;
  background-color: var(--white);
  border-radius: 5px;
  box-shadow: 3px 3px 8px #3333;
}
.authModal > p {
  margin: 0;
  padding: 0;
  line-height: 1;
  text-align: center;
}
.authInput {
  font-size: 1rem;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #3333;
}
.authBtn {
  font-size: 1rem;
  padding: 7px;
  border-radius: 5px;
  background-color: var(--blue);
  color: var(--white);
  border: 1px solid #3333;
  cursor: pointer;
}