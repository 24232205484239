:root {
  --blue: #0076ba;
  --green: #1db100;
  --yellow: #ffc107;
  --red: #dc3545;
  --white: #fff;
}
*, ::after, ::before {
  box-sizing: border-box;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  padding: 60px 15px;
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.firebase-emulator-warning {
  display: none !important;
}
h1 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 10px 0 20px;
}
h1 > span {
  font-weight: 600;
}
.btn {
  display: block;
  cursor: pointer;
  border-radius: 7px;
  border: 1px solid transparent;
  background-color: var(--blue);
  color: var(--white);
  text-align: center;
  padding: 10px 15px;
  font-weight: 600;
}
.btnLogout {
  background-color: var(--red);
}
.form-control {
  size: 100%;
  width: 100%;
  margin: 5px 0;
  font-size: 1rem;
  padding: 7px;
  border-radius: 5px;
  border: 1px solid #3333;
}
.inputError {
  color: var(--red);
  font-size: 12px;
  margin: 0;
}