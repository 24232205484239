.dashboard {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  
  gap: 10px;
}
.dashboardBtn {
  background: radial-gradient(circle at top right, var(--blue) 20%, #006097);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  border-radius: 7px;
  padding-bottom: 25px;
  aspect-ratio: 4/3;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  hyphens: auto;
  word-break: break-all;
  line-height: 1.1;
}
.dashboardBtn > svg, .dashboardBtn > img {
  height: 36px;
  font-size: 36px;
}
.dashboardBtn > .btn {
  position: absolute;
  left: 5px;
  bottom: 5px;
  background-color: var(--green);
  font-weight: bold;
  padding: 5px 9px;
}
.dashboardBtn > .btn::after {
  content: "→";
  margin-left: 10px;
}
.dashboardBtn.signOut {
  background: radial-gradient(circle at top right, var(--red) 20%, #b62b39);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 7px;
  padding: 10px;
  margin-top: 20px;;
  aspect-ratio: 10/2;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  font-size: 12px;
  hyphens: auto;
  word-break: break-all;
  line-height: 1.1;
}
.dashboardBtn.signOut > svg, dashboardBtn.signOut > img {
  height: 24px;
  font-size: 24px;
  margin-bottom: 10px;
}
.dashboardFooter {
  margin-top: 30px;
  font-size: 10px;
  color: var(--blue);
  opacity: .3;
}