.footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: linear-gradient(70deg, var(--blue) , var(--green) 150%);
  display: flex;
  align-items: stretch;
  justify-content: center;
}
.footerBtn {
  flex: 1 0 0;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  font-size: 1.4rem;
  color: var(--white);
  text-decoration: none;
  border: 1px solid #0002;
  transition: all .2s;
}
.footerBtn.active {
  padding-bottom: 2px;
  background-color: var(--green);
}
.footerBtn.active::before {
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  background-color: var(--green);
  border-radius: 5px 5px 0 0;
}